import {APP_PREFIX_PATH} from 'configs/AppConfig'

import {
  AnalyticsSVG,
  ContentNavSVG,
  BooksSVG,
  AudienceSVG,
  FacebookNavWhite,
  InstagramNavWhite,
  TikTokNavWhite,
  YoutubeNavWhite,
  SnapchatNavWhite,
  LinkedinNavWhite,
  DigitekaNavWhite,
  ThreadsNavWhite,
  ElectronIcon,
  DashboardIcon,
  FuseIcon,
  MapIcon,
  FacebookNavWhiteAlternate,
  InstagramNavWhiteAlternate,
  TikTokNavWhiteAlternate,
  YoutubeNavWhiteAlternate,
  SnapchatNavWhiteAlternate,
  LinkedinNavWhiteAlternate,
  DigitekaNavWhiteAlternate,
  ThreadsNavWhiteAlternate,
  AnalyticsSVGAlternate,
  InsightsSVGAlternate,
  DailymotionNavWhite,
  DailymotionNavWhiteAlternate,
  DailymotionIconWhite,
  DailymotionCustomNavIcon,
} from '../assets/logos/icon'

const navigation = [
  {
    key: 'dashboardHome',
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: 'headernav.menu.dashboard',
    component: import('views/app-views/dashboard-view'),
    headerTitle: 'headernav.menu.dashboard',
    icon: DashboardIcon,
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    submenu: []
  },
  {
    key: 'crossplatform',
    path: `${APP_PREFIX_PATH}/list/crossplatform`,
    title: 'headernav.menu.dashboard.crossplatform',
    component: import('views/app-views/list-view/dashboardCrossPosts'),
    headerTitle: 'headernav.menu.dashboard.crossplatform',
    disableForDemo: true,
    loopsiderOnly: true,
    // betaTesters: [7, 10, 11, 13, 19, 27, 6],
    icon: ElectronIcon,
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    submenu: []
  },
  {
    key: 'most-performing',
    path: `${APP_PREFIX_PATH}/list/most-performing`,
    title: 'headernav.menu.dashboard.mostPerforming',
    component: import('views/app-views/list-view/mostPerforming'),
    headerTitle: 'headernav.menu.dashboard.mostPerforming',
    disableForDemo: true,
    loopsiderOnly: true,
    // betaTesters: [7, 10, 11, 13, 19, 27, 6],
    icon: FuseIcon,
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    submenu: []
  },
  {
    key: 'benchmark',
    title: 'headernav.menu.benchmark',
    rights: ['user', 'analyst', 'admin', 'sysadmin', 'corrector'],
    icon: MapIcon,
    submenu: [
      {
        key: 'benchmarkFacebookPosts',
        path: `${APP_PREFIX_PATH}/list/benchmark/facebook`,
        title: 'headernav.menu.facebook',
        component: import('views/app-views/list-view/benchmark/facebook'),
        headerTitle: 'page.meta.title.benchmark.facebook.posts',
        parent: 'benchmark',
        rights: ['user', 'analyst', 'admin', 'sysadmin', 'corrector']
      },
      {
        key: 'benchmarkInstagramMedias',
        path: `${APP_PREFIX_PATH}/list/benchmark/instagram`,
        title: 'headernav.menu.instagram',
        component: import('views/app-views/list-view/benchmark/instagram'),
        headerTitle: 'page.meta.title.benchmark.facebook.posts',
        parent: 'benchmark',
        rights: ['user', 'analyst', 'admin', 'sysadmin', 'corrector']
      },
      {
        key: 'benchmarkYoutubeVideos',
        path: `${APP_PREFIX_PATH}/list/benchmark/youtube`,
        title: 'headernav.menu.youtube',
        component: import('views/app-views/list-view/benchmark/youtube'),
        headerTitle: 'page.meta.title.benchmark.youtube.videos',
        parent: 'benchmark',
        rights: ['user', 'analyst', 'admin', 'sysadmin', 'corrector']
      },
      {
        key: 'advertisers',
        path: `${APP_PREFIX_PATH}/list/advertisers`,
        title: 'headernav.menu.dashboard.advertisers',
        component: import('views/app-views/list-view/advertisers'),
        headerTitle: 'headernav.menu.dashboard.advertisers',
        parent: 'benchmark',
        disableForDemo: true,
        loopsiderOnly: true,
        betaTesters: [1, 2, 6],
        icon: ContentNavSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin', 'corrector']
      }
    ]
  },
  {
    key: 'facebook',
    title: 'headernav.menu.facebook',
    color: '#1877f2',
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    icon: FacebookNavWhite,
    iconAlternate: FacebookNavWhiteAlternate,
    socialIdentifier: 'fb',
    submenu: [
      {
        key: 'facebookPosts',
        icon: ContentNavSVG,
        path: `${APP_PREFIX_PATH}/list/facebook`,
        title: 'headernav.menu.posts',
        component: import('views/app-views/list-view/facebook'),
        headerTitle: 'page.meta.title.facebook.posts',
        parent: 'facebook',
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'facebookPost',
        path: `${APP_PREFIX_PATH}/details/facebook/:id`,
        title: 'page.meta.title.facebook.post',
        component: import('views/app-views/details-view/facebook'),
        headerTitle: 'page.meta.title.facebook.posts',
        parent: 'facebook',
        hidden: true,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'facebookAnalytics',
        path: `${APP_PREFIX_PATH}/analytics/facebook`,
        title: 'headernav.menu.analytics',
        component: import('views/app-views/analytics-view/facebook'),
        parent: 'facebook',
        headerTitle: 'page.meta.title.facebook.analytics',
        icon: AnalyticsSVG,
        iconAlternate: AnalyticsSVGAlternate,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'facebookInsights',
        path: `${APP_PREFIX_PATH}/insights/facebook`,
        title: 'headernav.menu.insights',
        component: import('views/app-views/insights-view/facebook'),
        parent: 'facebook',
        headerTitle: 'page.meta.title.facebook.insights',
        icon: InsightsSVGAlternate,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'facebookAudience',
        path: `${APP_PREFIX_PATH}/audience/facebook`,
        title: 'headernav.menu.audience',
        component: import('views/app-views/audience-view/facebook'),
        parent: 'facebook',
        headerTitle: 'page.meta.title.facebook.audience',
        icon: AudienceSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'facebookTests',
        path: `${APP_PREFIX_PATH}/tests/facebook`,
        title: 'headernav.menu.tests',
        component: import('views/app-views/list-view/facebook'),
        parent: 'facebook',
        headerTitle: 'page.meta.title.facebook.tests',
        icon: BooksSVG,
        loopsiderOnly: true,
        betaTesters: [1, 6],
        rights: ['analyst', 'admin', 'sysadmin']
      }
    ]
  },
  {
    key: 'instagram',
    title: 'headernav.menu.instagram',

    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    color: '#db1a8b',
    socialIdentifier: 'fb',
    icon: InstagramNavWhite,
    iconAlternate: InstagramNavWhiteAlternate,
    submenu: [
      {
        key: 'instagramMedias',
        icon: ContentNavSVG,
        path: `${APP_PREFIX_PATH}/list/instagram`,
        title: 'headernav.menu.posts',
        component: import('views/app-views/list-view/instagram'),
        parent: 'instagram',
        headerTitle: 'page.meta.title.instagram.medias',
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'instagramMedia',
        path: `${APP_PREFIX_PATH}/details/instagram/:id`,
        title: 'page.meta.title.instagram.post',
        component: import('views/app-views/details-view/instagram'),
        parent: 'instagram',
        headerTitle: 'page.meta.title.instagram.medias',
        hidden: true,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'instagramAnalytics',
        path: `${APP_PREFIX_PATH}/analytics/instagram`,
        title: 'headernav.menu.analytics',
        component: import('views/app-views/analytics-view/instagram'),
        parent: 'instagram',
        headerTitle: 'page.meta.title.instagram.analytics',
        icon: AnalyticsSVG,
        iconAlternate: AnalyticsSVGAlternate,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'instagramInsights',
        path: `${APP_PREFIX_PATH}/insights/instagram`,
        title: 'headernav.menu.insights',
        component: import('views/app-views/insights-view/instagram'),
        parent: 'instagram',
        headerTitle: 'page.meta.title.instagram.insights',
        icon: InsightsSVGAlternate,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      // {
      //   key: 'instagramStories',
      //   path: `${APP_PREFIX_PATH}/stories/instagram`,
      //   title: 'headernav.menu.stories',
      //   component: import('views/app-views/stories-view/instagram'),
      //   parent: 'instagram',
      //   headerTitle: 'page.meta.title.instagram.stories',
      //
      //   icon: ContentNavSVG,
      //   rights: ['user', 'analyst', 'admin', 'sysadmin']
      // },
      {
        key: 'instagramAudience',
        path: `${APP_PREFIX_PATH}/audience/instagram`,
        title: 'headernav.menu.audience',
        component: import('views/app-views/audience-view/instagram'),
        parent: 'instagram',
        headerTitle: 'page.meta.title.instagram.audience',
        icon: AudienceSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      }
    ]
  },
  {
    key: 'tiktok',
    title: 'headernav.menu.tiktok',

    socialIdentifier: 'tt',
    icon: TikTokNavWhite,
    iconAlternate: TikTokNavWhiteAlternate,
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    color: 'black',
    submenu: [
      {
        key: 'tiktokVideos',
        icon: ContentNavSVG,
        path: `${APP_PREFIX_PATH}/list/tiktok`,
        title: 'headernav.menu.posts',
        component: import('views/app-views/list-view/tiktok'),
        parent: 'tiktok',
        headerTitle: 'page.meta.title.tiktok.videos',
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'tiktokVideo',
        path: `${APP_PREFIX_PATH}/details/tiktok/:id`,
        title: 'page.meta.title.tiktok.post',
        component: import('views/app-views/details-view/tiktok'),
        parent: 'tiktok',
        headerTitle: 'page.meta.title.tiktok.videos',
        hidden: true,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'tiktokAnalytics',
        path: `${APP_PREFIX_PATH}/analytics/tiktok`,
        title: 'headernav.menu.analytics',
        component: import('views/app-views/analytics-view/tiktok'),
        parent: 'tiktok',
        headerTitle: 'page.meta.title.tiktok.analytics',
        icon: AnalyticsSVG,
        iconAlternate: AnalyticsSVGAlternate,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'tiktokInsights',
        path: `${APP_PREFIX_PATH}/insights/tiktok`,
        title: 'headernav.menu.insights',
        component: import('views/app-views/insights-view/tiktok'),
        parent: 'tiktok',
        headerTitle: 'page.meta.title.tiktok.insights',
        icon: InsightsSVGAlternate,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'tiktokAudience',
        path: `${APP_PREFIX_PATH}/audience/tiktok`,
        title: 'headernav.menu.audience',
        component: import('views/app-views/audience-view/tiktok'),
        parent: 'tiktok',
        headerTitle: 'page.meta.title.tiktok.audience',
        icon: AudienceSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      }
    ]
  },
  {
    key: 'youtube',
    title: 'headernav.menu.youtube',
    icon: YoutubeNavWhite,
    iconAlternate: YoutubeNavWhiteAlternate,
    socialIdentifier: 'yt',
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    color: 'black',
    submenu: [
      {
        key: 'youtubeVideos',
        icon: ContentNavSVG,
        path: `${APP_PREFIX_PATH}/list/youtube`,
        title: 'headernav.menu.posts',
        component: import('views/app-views/list-view/youtube'),
        parent: 'youtube',
        headerTitle: 'page.meta.title.youtube.videos',
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'youtubeAnalytics',
        path: `${APP_PREFIX_PATH}/analytics/youtube`,
        title: 'headernav.menu.analytics',
        component: import('views/app-views/analytics-view/youtube'),
        parent: 'youtube',
        headerTitle: 'page.meta.title.youtube.analytics',
        icon: AnalyticsSVG,
        iconAlternate: AnalyticsSVGAlternate,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'youtubeVideo',
        path: `${APP_PREFIX_PATH}/details/youtube/:id`,
        title: 'page.meta.title.youtube.video',
        component: import('views/app-views/details-view/youtube'),
        parent: 'youtube',
        headerTitle: 'page.meta.title.youtube.videos',
        hidden: true,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'youtubeInsights',
        path: `${APP_PREFIX_PATH}/insights/youtube`,
        title: 'headernav.menu.insights',
        component: import('views/app-views/insights-view/youtube'),
        parent: 'youtube',
        headerTitle: 'page.meta.title.youtube.insights',
        disableForDemo: true,
        icon: InsightsSVGAlternate,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      }
      // {
      //   key: 'youtubeAudience',
      //   path: `${APP_PREFIX_PATH}/audience/youtube`,
      //   title: 'headernav.menu.audience',
      //   component: import('views/app-views/audience-view/youtube'),
      //   parent: 'youtube',
      //   headerTitle: 'page.meta.title.youtube.audience',
      //
      //   icon: AudienceSVG,
      //   loopsiderOnly: true,
      //   rights: ['dev']
      // },
    ]
  },
  {
    key: 'snapchat',
    title: 'headernav.menu.snapchat',
    icon: SnapchatNavWhite,
    iconAlternate: SnapchatNavWhiteAlternate,
    socialIdentifier: 'sc',
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    color: 'black',
    submenu: [
      {
        key: 'snapchatPosts',
        icon: ContentNavSVG,
        path: `${APP_PREFIX_PATH}/list/snapchat`,
        title: 'headernav.menu.posts',
        component: import('views/app-views/list-view/snapchat'),
        parent: 'snapchat',
        headerTitle: 'page.meta.title.snapchat.content',
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'snapchatPost',
        path: `${APP_PREFIX_PATH}/details/snapchat/:id`,
        title: 'page.meta.title.snapchat.post',
        component: import('views/app-views/details-view/snapchat'),
        parent: 'snapchat',
        headerTitle: 'page.meta.title.snapchat.content',
        hidden: true,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'snapchatAnalytics',
        path: `${APP_PREFIX_PATH}/analytics/snapchat`,
        title: 'headernav.menu.analytics',
        component: import('views/app-views/analytics-view/snapchat'),
        parent: 'snapchat',
        headerTitle: 'page.meta.title.snapchat.analytics',
        icon: AnalyticsSVG,
        iconAlternate: AnalyticsSVGAlternate,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'snapchatInsights',
        path: `${APP_PREFIX_PATH}/insights/snapchat`,
        title: 'headernav.menu.insights',
        component: import('views/app-views/insights-view/snapchat'),
        parent: 'snapchat',
        headerTitle: 'page.meta.title.snapchat.insights',
        icon: InsightsSVGAlternate,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      }
      /*{
        key: 'snapchatAudience',
        path: `${APP_PREFIX_PATH}/audience/snapchat`,
        title: 'headernav.menu.audience',
        component: import('views/app-views/audience-view/snapchat'),
        parent: 'snapchat',
        headerTitle: 'page.meta.title.snapchat.audience',
        
        icon: AudienceSVG,
        loopsiderOnly: true,
        rights: ['dev']
      },*/
    ]
  },
  {
    key: 'linkedin',
    title: 'headernav.menu.linkedin',
    socialIdentifier: 'li',
    icon: LinkedinNavWhite,
    iconAlternate: LinkedinNavWhiteAlternate,
    // TODO: LinkedIn delete for release
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    color: 'black',
    submenu: [
      {
        key: 'linkedinPosts',
        icon: ContentNavSVG,
        path: `${APP_PREFIX_PATH}/list/linkedin`,
        title: 'headernav.menu.posts',
        component: import('views/app-views/list-view/linkedin'),
        parent: 'linkedin',
        headerTitle: 'page.meta.title.linkedin.posts',
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'linkedinDetails',
        path: `${APP_PREFIX_PATH}/details/linkedin/:id`,
        title: 'page.meta.title.linkedin.posts',
        component: import('views/app-views/details-view/linkedin'),
        parent: 'linkedin',
        headerTitle: 'page.meta.title.linkedin.content',
        hidden: true,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'linkedinAnalytics',
        path: `${APP_PREFIX_PATH}/analytics/linkedin`,
        title: 'headernav.menu.analytics',
        component: import('views/app-views/analytics-view/linkedin'),
        parent: 'linkedin',
        headerTitle: 'page.meta.title.linkedin.analytics',
        icon: AnalyticsSVG,
        iconAlternate: AnalyticsSVGAlternate,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'linkedinInsights',
        path: `${APP_PREFIX_PATH}/insights/linkedin`,
        title: 'headernav.menu.insights',
        component: import('views/app-views/insights-view/linkedin'),
        parent: 'linkedin',
        headerTitle: 'page.meta.title.linkedin.insights',
        disableForDemo: true,
        icon: InsightsSVGAlternate,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      }
    ]
  },
  {
    key: 'digiteka',
    title: 'headernav.menu.digiteka',
    socialIdentifier: 'dk',
    icon: DigitekaNavWhite,
    iconAlternate: DigitekaNavWhiteAlternate,
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    color: 'black',
    submenu: [
      {
        key: 'digitekaVideos',
        icon: ContentNavSVG,
        path: `${APP_PREFIX_PATH}/list/digiteka`,
        title: 'headernav.menu.posts',
        component: import('views/app-views/list-view/digiteka'),
        parent: 'digiteka',
        headerTitle: 'page.meta.title.digiteka.videos',
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'digitekaAnalyticsAudience',
        path: `${APP_PREFIX_PATH}/analytics/digiteka/audience`,
        title: 'headernav.menu.audience',
        component: import('views/app-views/analytics-view/digiteka'),
        parent: 'digiteka',
        headerTitle: 'page.meta.title.digiteka.analytics.audience',
        icon: AnalyticsSVG,
        iconAlternate: AnalyticsSVGAlternate,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'digitekaAnalyticsMonetization',
        path: `${APP_PREFIX_PATH}/analytics/digiteka/monetization`,
        title: 'headernav.menu.monetization',
        component: import('views/app-views/analytics-view/digiteka'),
        parent: 'digiteka',
        headerTitle: 'page.meta.title.digiteka.analytics.monetization',
        icon: AnalyticsSVG,
        iconAlternate: AnalyticsSVGAlternate,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },

      {
        key: 'digitekaVideo',
        path: `${APP_PREFIX_PATH}/details/digiteka/:id`,
        title: 'page.meta.title.digiteka.video',
        component: import('views/app-views/details-view/digiteka'),
        parent: 'digiteka',
        headerTitle: 'page.meta.title.digiteka.videos',
        hidden: true,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      }
    ]
  },
  {
    key: 'threads',
    title: 'headernav.menu.threads',
    socialIdentifier: 'th',
    icon: ThreadsNavWhite,
    iconAlternate: ThreadsNavWhiteAlternate,
    // TODO: Threads uncomment
    displayForDemo: true,
    rights: ['sysadmin'],
    // rights: ['user', 'analyst', 'admin', 'sysadmin'],
    color: 'black',
    submenu: [
      {
        key: 'threadsPosts',
        icon: ContentNavSVG,
        path: `${APP_PREFIX_PATH}/list/threads`,
        title: 'headernav.menu.posts',
        component: import('views/app-views/list-view/threads'),
        parent: 'threads',
        headerTitle: 'page.meta.title.threads.posts',
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      // {
      //   key: 'threadsDetails',
      //   path: `${APP_PREFIX_PATH}/details/threads/:id`,
      //   title: 'page.meta.title.threads.posts',
      //   component: import('views/app-views/details-view/threads'),
      //   parent: 'threads',
      //   headerTitle: 'page.meta.title.threads.content',
      //   hidden: true,
      //   rights: ['user', 'analyst', 'admin', 'sysadmin']
      // },
      {
        key: 'threadsAnalytics',
        path: `${APP_PREFIX_PATH}/analytics/threads`,
        title: 'headernav.menu.analytics',
        component: import('views/app-views/analytics-view/threads'),
        parent: 'threads',
        headerTitle: 'page.meta.title.threads.analytics',
        icon: AnalyticsSVG,
        iconAlternate: AnalyticsSVGAlternate,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      }
      // {
      //   key: 'threadsInsights',
      //   path: `${APP_PREFIX_PATH}/insights/threads`,
      //   title: 'headernav.menu.insights',
      //   component: import('views/app-views/insights-view/threads'),
      //   parent: 'threads',
      //   headerTitle: 'page.meta.title.threads.insights',
      //   disableForDemo: true,
      //   icon: InsightsSVGAlternate,
      //   rights: ['user', 'analyst', 'admin', 'sysadmin']
      // }
    ]
  },
  {
    key: 'dailymotion',
    title: 'headernav.menu.dailymotion',
    socialIdentifier: 'da',
    icon: DailymotionCustomNavIcon,
    iconAlternate: DailymotionCustomNavIcon,
    displayForDemo: true,
    rights: ['sysadmin'],
    loopsiderOnly: true,
    // rights: ['user', 'analyst', 'admin', 'sysadmin'],
    color: 'black',
    submenu: [
      {
        key: 'dailymotionPosts',
        icon: ContentNavSVG,
        path: `${APP_PREFIX_PATH}/list/dailymotion`,
        title: 'headernav.menu.posts',
        component: import('views/app-views/list-view/dailymotion'),
        parent: 'dailymotion',
        headerTitle: 'page.meta.title.dailymotion.posts',
        rights: ['sysadmin']
      },
      {
        key: 'dailymotionAnalytics',
        path: `${APP_PREFIX_PATH}/analytics/dailymotion`,
        title: 'headernav.menu.analytics',
        component: import('views/app-views/analytics-view/dailymotion'),
        parent: 'dailymotion',
        headerTitle: 'page.meta.title.dailymotion.analytics',
        icon: AnalyticsSVG,
        iconAlternate: AnalyticsSVGAlternate,
        rights: ['sysadmin']
      },
    ]
  },
  /*{
    key: 'campaign-view',
    title: 'headernav.menu.campaign',
    path: `${APP_PREFIX_PATH}/campaigns`,
    component: import('views/app-views/campaign-view'),
    headerTitle: 'headernav.menu.campaign',
    onHeader: process.env.REACT_APP_NODE_ENV === 'production' ? false : true,
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    icon: CampaignNavSVG,
    submenu: [],
    socialIdentifier: undefined
  },*/
  {
    key: 'similars',
    title: 'headernav.menu.similars',
    icon: ContentNavSVG,
    path: `${APP_PREFIX_PATH}/list/similars`,
    component: import('views/app-views/list-view/similars'),
    headerTitle: 'headernav.menu.similars',
    hidden: true,
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    socialIdentifier: undefined,
    submenu: []
  }
]

export default navigation
