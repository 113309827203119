import {
  ApartmentOutlined,
  FilePdfOutlined,
  RetweetOutlined,
  ShareAltOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons'
import {ADMIN_PREFIX_PATH} from 'configs/AppConfig'
import {
  DailymotionCustomNavIcon,
  DailymotionNavWhite, DailymotionNavWhiteAlternate,
  FacebookNavWhite,
  FacebookNavWhiteAlternate,
  InstagramNavWhite,
  InstagramNavWhiteAlternate,
  LinkedinNavWhite,
  LinkedinNavWhiteAlternate,
  SnapchatNavWhite,
  SnapchatNavWhiteAlternate,
  TikTokNavWhite,
  TikTokNavWhiteAlternate,
  TwitterNavWhite,
  TwitterNavWhiteAlternate,
  YoutubeNavWhite,
  YoutubeNavWhiteAlternate,
} from '../assets/logos/icon'

function generatePath(item) {
  let dateParam = item.date || 'defaultDateValue' // Use the date from the item, or a default
  let pagesParam = item.pages.join('%2C') // Join the pages array into a string

  return `${ADMIN_PREFIX_PATH}/MailReport?date=${dateParam}&groups=18&pages=${pagesParam}&template=${item.template}&filter=${item.filter}&title=${item.reportTitle}`
}

const navigation = [
  {
    key: 'users',
    rights: ['sysadmin', 'admin'],
    title: 'admin.nav.users',
    icon: UsergroupAddOutlined,
    submenu: [
      {
        rights: ['sysadmin'],
        key: 'usersClients',
        path: `${ADMIN_PREFIX_PATH}/users/clients`,
        title: 'admin.nav.users.clients',
        component: import('views/admin-views/Users/Clients'),
        headerTitle: 'page.meta.title.admin.users.clients',
        parent: 'users'
      },
      {
        rights: ['sysadmin', 'admin'],
        key: 'usersGroups',
        path: `${ADMIN_PREFIX_PATH}/users/groups`,
        title: 'admin.nav.users.brand_list',
        component: import('views/admin-views/Users/Companies/Groups'),
        headerTitle: 'admin.nav.users.brand_list',
        parent: 'users'
      },
      {
        rights: ['sysadmin', 'admin'],
        key: 'usersCompanies',
        path: `${ADMIN_PREFIX_PATH}/users/companies`,
        title: 'admin.nav.users.companies',
        component: import('views/admin-views/Users/Companies/List'),
        headerTitle: 'page.meta.title.admin.users.companies',
        parent: 'users'
      },
      /*{
        
        rights: ['sysadmin'],
        key: 'usersBenchmark',
        path: `${ADMIN_PREFIX_PATH}/users/benchmark`,
        title: 'page.meta.title.admin.users.benchmark',
        component: import('views/admin-views/Users/Benchmark'),
        headerTitle: 'page.meta.title.admin.users.benchmark',
        parent: 'users'
      },*/
      {
        rights: ['sysadmin', 'admin'],
        key: 'usersRoles',
        path: `${ADMIN_PREFIX_PATH}/users/roles`,
        title: 'admin.nav.users.roles',
        component: import('views/admin-views/Users/Roles'),
        headerTitle: 'page.meta.title.admin.users.roles',
        parent: 'users'
      }
    ]
  },
  {
    rights: ['sysadmin'],
    key: 'benchmark',
    title: 'admin.nav.Benchmark',
    icon: ApartmentOutlined,
    submenu: [
      {
        rights: ['sysadmin'],
        key: 'alerts',
        path: `${ADMIN_PREFIX_PATH}/alerts`,
        title: 'headernav.menu.benchmark.alerts',
        component: import('views/admin-views/Benchmark'),
        headerTitle: 'headernav.menu.benchmark.alerts',
        parent: 'benchmark',
      },
      {
        rights: ['sysadmin'],
        key: 'benchmarkLists',
        path: `${ADMIN_PREFIX_PATH}/benchmark/lists`,
        title: 'page.meta.title.admin.benchmark.lists',
        component: import('views/admin-views/Benchmark/Lists'),
        headerTitle: 'page.meta.title.admin.users.benchmark',
        parent: 'benchmark'
      },
      {
        rights: ['sysadmin'],
        key: 'benchmarkAccounts',
        path: `${ADMIN_PREFIX_PATH}/benchmark/AccountList`,
        title: 'page.meta.title.admin.benchmark.accountsV2',
        component: import('views/admin-views/Benchmark/AccountList'),
        headerTitle: 'page.meta.title.admin.users.benchmark',
        parent: 'benchmark'
      },
      {
        rights: ['sysadmin'],
        key: 'benchmarkAccountsBrandsLists',
        path: `${ADMIN_PREFIX_PATH}/benchmark/AccountBrandList`,
        title: 'page.meta.title.admin.benchmark.account_brand_list',
        component: import('views/admin-views/Benchmark/AccountBrandList'),
        headerTitle: 'page.meta.title.admin.users.benchmark',
        parent: 'benchmark'
      }
    ]
  },
  {
    rights: ['sysadmin', 'admin', 'accountmanager'],
    key: 'socialsAccounts',
    title: 'admin.nav.socialAccounts',
    icon: ShareAltOutlined,
    submenu: [
      {
        key: 'socialsAccountsFacebook',
        icon: FacebookNavWhite,
        iconAlternate: FacebookNavWhiteAlternate,
        path: `${ADMIN_PREFIX_PATH}/socials/facebook`,
        title: 'admin.nav.socialAccounts.facebook',
        component: import('views/admin-views/Socials/FacebookPages'),
        headerTitle: 'page.meta.title.admin.socialAccounts.facebook',
        parent: 'socialsAccounts',
        socialNetworkIdentifier: 'fb'
      },
      {
        key: 'socialsAccountsTiktok',
        icon: TikTokNavWhite,
        iconAlternate: TikTokNavWhiteAlternate,
        path: `${ADMIN_PREFIX_PATH}/socials/tiktok`,
        title: 'admin.nav.socialAccounts.tiktok',
        component: import('views/admin-views/Socials/TiktokAccounts'),
        headerTitle: 'page.meta.title.admin.socialAccounts.tiktok',
        parent: 'socialsAccounts',
        socialNetworkIdentifier: 'tt'
      },
      {
        key: 'socialsAccountsYoutube',
        icon: YoutubeNavWhite,
        iconAlternate: YoutubeNavWhiteAlternate,
        path: `${ADMIN_PREFIX_PATH}/socials/youtube`,
        title: 'admin.nav.socialAccounts.youtube',
        component: import('views/admin-views/Socials/YoutubeChannels'),
        headerTitle: 'page.meta.title.admin.socialAccounts.youtube',
        parent: 'socialsAccounts',
        socialNetworkIdentifier: 'yt'
      },
      {
        key: 'socialsAccountsSnapchat',
        icon: SnapchatNavWhite,
        iconAlternate: SnapchatNavWhiteAlternate,
        path: `${ADMIN_PREFIX_PATH}/socials/snapchat`,
        title: 'admin.nav.socialAccounts.snapchat',
        component: import('views/admin-views/Socials/SnapchatAccounts'),
        headerTitle: 'page.meta.title.admin.socialAccounts.snapchat',
        parent: 'socialsAccounts',
        socialNetworkIdentifier: 'sc'
      },
      {
        key: 'socialsAccountsLinkedin',
        icon: LinkedinNavWhite,
        iconAlternate: LinkedinNavWhiteAlternate,
        path: `${ADMIN_PREFIX_PATH}/socials/linkedin`,
        title: 'admin.nav.socialAccounts.linkedin',
        component: import('views/admin-views/Socials/LinkedinAccounts'),
        headerTitle: 'page.meta.title.admin.socialAccounts.linkedin',
        parent: 'socialsAccounts',
        socialNetworkIdentifier: 'li'
      },
      {
        rights: ['sysadmin'],
        key: 'socialsAccountsTwitter',
        icon: TwitterNavWhite,
        iconAlternate: TwitterNavWhiteAlternate,
        path: `${ADMIN_PREFIX_PATH}/socials/twitter`,
        title: 'admin.nav.socialAccounts.twitter',
        component: import('views/admin-views/Socials/TwitterAccounts'),
        headerTitle: 'admin.nav.socialAccounts.twitter',
        parent: 'socialsAccounts',
        socialNetworkIdentifier: 'tw'
      },
      {
        rights: ['sysadmin'],
        key: 'socialsAccountsDailymotion',
        icon: DailymotionCustomNavIcon,
        iconAlternate: DailymotionCustomNavIcon,
        path: `${ADMIN_PREFIX_PATH}/socials/dailymotion`,
        title: 'admin.nav.socialAccounts.dailymotion',
        component: import('views/admin-views/Socials/DailymotionOwners'),
        headerTitle: 'admin.nav.socialAccounts.dailymotion',
        parent: 'socialsAccounts',
        socialNetworkIdentifier: 'da'
      },
      // {
      //   key: 'socialsAccountsGoogle',
      //   icon: GoogleOutlined ,
      //   path: `${ADMIN_PREFIX_PATH}/socials/google`,
      //   title: 'admin.nav.socialAccounts.google',
      //   component: import('views/admin-views/Socials/GoogleAccounts'),
      //   headerTitle: 'page.meta.title.admin.socialAccounts.google',
      //   parent: 'socialsAccounts',
      //   socialNetworkIdentifier: 'yt',
      // },
    ]
  },
  {
    rights: ['sysadmin', 'corrector'],
    key: 'categories',
    title: 'admin.nav.categories',
    icon: ApartmentOutlined,
    submenu: [
      {
        key: 'categoriesTree',
        path: `${ADMIN_PREFIX_PATH}/categories/tree`,
        title: 'headernav.menu.category.tree',
        component: import('views/admin-views/Categories/Tree'),
        headerTitle: 'headernav.menu.category.tree',
        parent: 'categories',
        rights: ['sysadmin']
      },
      {
        key: 'categoriesCorrectionsFacebook',
        icon: FacebookNavWhite,
        iconAlternate: FacebookNavWhiteAlternate,
        path: `${ADMIN_PREFIX_PATH}/categories/corrections/facebook`,
        title: 'headernav.menu.facebook',
        component: import('views/admin-views/Categories/Corrections/Facebook'),
        headerTitle: 'headernav.menu.facebook',
        parent: 'categories',
        socialNetworkIdentifier: 'fb'
      },
      {
        key: 'categoriesCorrectionsInstagram',
        icon: InstagramNavWhite,
        iconAlternate: InstagramNavWhiteAlternate,
        path: `${ADMIN_PREFIX_PATH}/categories/corrections/instagram`,
        title: 'headernav.menu.instagram',
        component: import('views/admin-views/Categories/Corrections/Instagram'),
        headerTitle: 'headernav.menu.instagram',
        parent: 'categories',
        socialNetworkIdentifier: 'ig'
      },
      {
        key: 'categoriesCorrectionsTiktok',
        icon: TikTokNavWhite,
        iconAlternate: TikTokNavWhiteAlternate,
        path: `${ADMIN_PREFIX_PATH}/categories/corrections/tiktok`,
        title: 'headernav.menu.tiktok',
        component: import('views/admin-views/Categories/Corrections/Tiktok'),
        headerTitle: 'headernav.menu.tiktok',
        parent: 'categories',
        socialNetworkIdentifier: 'tt'
      },
      {
        key: 'categoriesCorrectionsYoutube',
        icon: YoutubeNavWhite,
        iconAlternate: YoutubeNavWhiteAlternate,
        path: `${ADMIN_PREFIX_PATH}/categories/corrections/youtube`,
        title: 'headernav.menu.youtube',
        component: import('views/admin-views/Categories/Corrections/Youtube'),
        headerTitle: 'headernav.menu.youtube',
        parent: 'categories',
        socialNetworkIdentifier: 'yt'
      },
      {
        key: 'categoriesCorrectionsSnapchat',
        icon: SnapchatNavWhite,
        iconAlternate: SnapchatNavWhiteAlternate,
        path: `${ADMIN_PREFIX_PATH}/categories/corrections/snapchat`,
        title: 'headernav.menu.snapchat',
        component: import('views/admin-views/Categories/Corrections/Snapchat'),
        headerTitle: 'headernav.menu.snapchat',
        parent: 'categories',
        socialNetworkIdentifier: 'sc'
      },
      {
        key: 'categoriesCorrectionsLinkedin',
        icon: LinkedinNavWhite,
        iconAlternate: LinkedinNavWhiteAlternate,
        path: `${ADMIN_PREFIX_PATH}/categories/corrections/linkedin`,
        title: 'headernav.menu.linkedin',
        component: import('views/admin-views/Categories/Corrections/Linkedin'),
        headerTitle: 'headernav.menu.linkedin',
        parent: 'categories',
        socialNetworkIdentifier: 'li'
      },
      {
        key: 'benchmarkFacebookPosts',
        path: `${ADMIN_PREFIX_PATH}/categories/correction/benchmark/facebook`,
        title: 'headernav.menu.benchmark.facebook.posts.corrector',
        component: import('views/app-views/list-view/benchmark/facebook'),
        headerTitle: 'page.meta.title.benchmark.facebook.posts',
        parent: 'categories',
        rights: ['user', 'admin', 'sysadmin', 'corrector']
      },
      {
        key: 'benchmarkInstagramMedias',
        path: `${ADMIN_PREFIX_PATH}/categories/correction/benchmark/instagram`,
        title: 'headernav.menu.benchmark.instagram.posts.corrector',
        component: import('views/app-views/list-view/benchmark/instagram'),
        headerTitle: 'page.meta.title.benchmark.facebook.posts',
        parent: 'categories',
        rights: ['user', 'admin', 'sysadmin', 'corrector']
      },
      {
        key: 'benchmarkYoutubeVideos',
        path: `${ADMIN_PREFIX_PATH}/categories/correction/benchmark/youtube`,
        title: 'headernav.menu.benchmark.youtube.posts.corrector',
        component: import('views/app-views/list-view/benchmark/youtube'),
        headerTitle: 'page.meta.title.benchmark.youtube.videos',
        parent: 'categories',
        rights: ['user', 'admin', 'sysadmin', 'corrector']
      }
      // {
      //   key: 'categoriesList',
      //   icon: UnorderedListOutlined style={{fontSize: '1.6em', marginLeft: '2px', marginRight: '10px'}} ,
      //   path: `${ADMIN_PREFIX_PATH}/categories/list`,
      //   title: 'admin.nav.categories.list',
      //   component: import('views/admin-views/Categories/List'),
      //   parent: 'categories',
      //   headerTitle: 'page.meta.title.admin.categories.list'
      // }
    ]
  },
  {
    rights: ['sysadmin', 'corrector'],
    key: 'subjects',
    title: 'admin.nav.subjects',
    icon: ApartmentOutlined,
    submenu: [
      {
        key: 'subjectsBenchmarkInsights',
        path: `${ADMIN_PREFIX_PATH}/subjects/insights/benchmark`,
        title: 'headernav.menu.subjects.insights.benchmark',
        component: import('views/admin-views/Subjects/Benchmark/Insights'),
        headerTitle: 'headernav.menu.subjects.insights.benchmark',
        parent: 'subjects',
        rights: ['sysadmin']
      },
      {
        key: 'subjectsBenchmarkContents',
        path: `${ADMIN_PREFIX_PATH}/subjects/contents/benchmark`,
        title: 'headernav.menu.subjects.contents.benchmark',
        component: import('views/admin-views/Subjects/Benchmark/Contents'),
        headerTitle: 'headernav.menu.subjects.contents.benchmark',
        parent: 'subjects',
        rights: ['sysadmin']
      }
    ]
  },
  {
    rights: ['sysadmin'],
    key: 'crosspost',
    title: 'admin.nav.similars',
    icon: RetweetOutlined,
    submenu: [
      {
        key: 'similarCorrect',
        path: `${ADMIN_PREFIX_PATH}/similars/correct`,
        title: 'admin.nav.similars.correct',
        component: import('views/admin-views/Similars'),
        parent: 'topic',
        headerTitle: 'page.meta.title.admin.similars.correct'
      },
      {
        key: 'CorrectionById',
        hidden: true,
        path: `${ADMIN_PREFIX_PATH}/similars/correctionById`,
        title: ' ',
        component: import('views/admin-views/Similars/correctionById'),
        parent: 'topic',
        headerTitle: 'CorrectionById'
      }
    ]
  },
  {
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    key: 'reports',
    title: 'admin.nav.reports',
    betaTesters: [10],
    icon: ApartmentOutlined,
    submenu: [
      {
        key: 'reports',
        path: `${ADMIN_PREFIX_PATH}/reports`,
        title: 'headernav.menu.reports.title',
        component: import('views/admin-views/ClientMailReport/Reports'),
        headerTitle: 'headernav.menu.reports.title',
        parent: 'benchmark',
        rights: ['user', 'analyst', 'admin', 'sysadmin'],
        betaTesters: [10]
      }
    ]
  },
  {
    key: 'mailReport',
    title: 'admin.nav.users.report',
    icon: FilePdfOutlined,
    path: `${ADMIN_PREFIX_PATH}/MailReport`,
    disableQuickOpen: true,
    headerTitle: 'admin.nav.users.report',
    rights: ['sysadmin'],
    submenu: [
      // ALTICE
      {
        key: 'altice',
        path: `${ADMIN_PREFIX_PATH}/MailReport`,
        disableQuickOpen: true,
        title: 'Altice',
        component: import('views/admin-views/MailReport'),
        parent: 'mailReport',
        headerTitle: 'admin.nav.users.report',
        submenu: [
          // ALTICE ENTERTAINMENT
          {
            key: 'altice_entertainment',
            path: `${ADMIN_PREFIX_PATH}/MailReport`,
            disableQuickOpen: true,
            title: 'Altice Entertainment',
            component: import('views/admin-views/MailReport'),
            parent: 'altice',
            headerTitle: 'admin.nav.users.report',
            submenu: [
              {
                key: 'altice_entertainment_rmc_daily',
                template: 'ALTICE_ENTERTAINMENT_DAILY',
                date: 'yesterday',
                pages: [92, 93, 94, 95, 433],
                filter: true,
                title: 'RMC - Quotidien', // Button title
                reportTitle: 'ALTICE_RMC_ENTERTAINMENT', // Report Title
                component: import('views/admin-views/MailReport'),
                parent: 'altice_entertainment',
                headerTitle: 'admin.nav.users.report',
                get path() {
                  return generatePath(this)
                }
                // sys_client: 2
              },
              {
                key: 'altice_entertainment_rmc_hebdo',
                template: 'ALTICE_ENTERTAINMENT_HEBDO',
                date: 'lastWeek',
                pages: [92, 93, 94, 95, 433],
                filter: true,
                title: 'RMC - Hebdo', // Button title
                reportTitle: 'ALTICE_RMC_ENTERTAINMENT', // Report Title
                component: import('views/admin-views/MailReport'),
                parent: 'altice_entertainment',
                headerTitle: 'admin.nav.users.report',
                get path() {
                  return generatePath(this)
                }
                // sys_client: 2
              },
              {
                key: 'altice_entertainment_rmc_monthly',
                template: 'ALTICE_ENTERTAINMENT_MONTHLY',
                date: 'lastMonth',
                pages: [92, 93, 94, 95, 433],
                filter: true,
                title: 'RMC - Monthly', // Button title
                reportTitle: 'ALTICE_RMC_ENTERTAINMENT', // Report Title
                component: import('views/admin-views/MailReport'),
                parent: 'altice_entertainment',
                headerTitle: 'admin.nav.users.report',
                get path() {
                  return generatePath(this)
                }
              }
            ]
          },
          // ALTICE NEWS
          {
            key: 'altice_news',
            path: `${ADMIN_PREFIX_PATH}/MailReport`,
            disableQuickOpen: true,
            title: 'Altice News',
            component: import('views/admin-views/MailReport'),
            parent: 'altice',
            headerTitle: 'admin.nav.users.report',
            submenu: [
              // ALTICE NEWS MONETISATION
              {
                key: 'altice_news_money',
                path: `${ADMIN_PREFIX_PATH}/MailReport`,
                disableQuickOpen: true,
                title: 'Monetisation',
                component: import('views/admin-views/MailReport'),
                parent: 'altice_news',
                headerTitle: 'admin.nav.users.report',
                submenu: [
                  // MONETISATION RMC
                  {
                    key: 'altice_news_monetisation_rmc',
                    path: `${ADMIN_PREFIX_PATH}/MailReport`,
                    disableQuickOpen: true,
                    title: 'RMC',
                    component: import('views/admin-views/MailReport'),
                    parent: 'altice_news_money',
                    headerTitle: 'admin.nav.users.report',
                    submenu: [
                      {
                        key: 'altice_news_monetization_rmc_hebdo',
                        template: 'ALTICE_NEWS_MONEY_RMC_HEBDO',
                        date: 'lastWeek',
                        pages: [110, 107, 29, 288, 26, 111, 118],
                        filter: true,
                        title: 'RMC - Hebdo', // Button title
                        reportTitle: 'ALTICE_RMC_MONETISATION', // Report Title
                        component: import('views/admin-views/MailReport'),
                        parent: 'altice_news_monetisation_rmc',
                        headerTitle: 'admin.nav.users.report',
                        get path() {
                          return generatePath(this)
                        }
                      },
                      {
                        key: 'altice_news_monetization_rmc_montlhy',
                        template: 'ALTICE_NEWS_MONEY_RMC_MONTHLY',
                        date: 'lastMonth',
                        pages: [110, 107, 29, 288, 26, 111, 118],
                        filter: true,
                        title: 'RMC - Monthly', // Button title
                        reportTitle: 'ALTICE_RMC_MONETISATION', // Report Title
                        component: import('views/admin-views/MailReport'),
                        parent: 'altice_news_monetisation_rmc',
                        headerTitle: 'admin.nav.users.report',
                        get path() {
                          return generatePath(this)
                        }
                      },
                      {
                        key: 'altice_news_monetization_rmc_sport_hebdo',
                        template: 'ALTICE_NEWS_MONEY_RMC_SPORT_HEBDO',
                        date: 'lastWeek',
                        pages: [112, 113, 114, 115],
                        filter: true,
                        title: 'RMC Sport - Hebdo', // Button title
                        reportTitle: 'ALTICE_RMC_MONETISATION', // Report Title
                        component: import('views/admin-views/MailReport'),
                        parent: 'altice_news_monetisation_rmc',
                        headerTitle: 'admin.nav.users.report',
                        get path() {
                          return generatePath(this)
                        }
                      },
                      {
                        key: 'altice_news_monetization_rmc_sport_montlhy',
                        template: 'ALTICE_NEWS_MONEY_RMC_SPORT_MONTHLY',
                        date: 'lastMonth',
                        pages: [112, 113, 114, 115],
                        filter: true,
                        title: 'RMC Sport - Monthly', // Button title
                        reportTitle: 'ALTICE_RMC_SPORT_MONETISATION', // Report Title
                        component: import('views/admin-views/MailReport'),
                        parent: 'altice_news_monetisation_rmc',
                        headerTitle: 'admin.nav.users.report',
                        get path() {
                          return generatePath(this)
                        }
                      }
                    ]
                  },
                  // MONETISATION BFMTV
                  {
                    key: 'altice_news_monetisation_bfmtv',
                    path: `${ADMIN_PREFIX_PATH}/MailReport`,
                    disableQuickOpen: true,
                    title: 'BFMTV',
                    component: import('views/admin-views/MailReport'),
                    parent: 'altice_news_money',
                    headerTitle: 'admin.nav.users.report',
                    submenu: [
                      {
                        key: 'altice_news_monetization_bfmtv_hebdo',
                        template: 'ALTICE_NEWS_MONEY_BFMTV_HEBDO',
                        date: 'lastWeek',
                        pages: [24, 80, 79, 67, 74, 71],
                        filter: true,
                        title: 'BFMTV - Hebdo', // Button title
                        reportTitle: 'ALTICE_BFMTV_MONETISATION', // Report Title
                        component: import('views/admin-views/MailReport'),
                        parent: 'altice_news_monetisation_bfmtv',
                        headerTitle: 'admin.nav.users.report',
                        get path() {
                          return generatePath(this)
                        }
                      },
                      {
                        key: 'altice_news_monetization_bfmtv_montlhy',
                        template: 'ALTICE_NEWS_MONEY_BFMTV_MONTHLY',
                        date: 'lastMonth',
                        pages: [24, 80, 79, 67, 74, 71],
                        filter: true,
                        title: 'BFMTV - Monthly', // Button title
                        reportTitle: 'ALTICE_BFMTV_MONETISATION', // Report Title
                        component: import('views/admin-views/MailReport'),
                        parent: 'altice_news_monetisation_bfmtv',
                        headerTitle: 'admin.nav.users.report',
                        get path() {
                          return generatePath(this)
                        }
                      }
                    ]
                  }
                ]
              },
              // BFMTV
              {
                key: 'altice_news_bfmtv',
                path: `${ADMIN_PREFIX_PATH}/MailReport`,
                disableQuickOpen: true,
                title: 'BFMTV',
                component: import('views/admin-views/MailReport'),
                parent: 'altice_news',
                headerTitle: 'admin.nav.users.report',
                submenu: [
                  {
                    key: 'altice_news_bfmtv_hebdo',
                    template: 'ALTICE_NEWS_BFMTV_HEBDO',
                    date: 'lastWeek',
                    pages: [24, 80, 79, 67, 74, 71],
                    filter: true,
                    title: 'BFMTV - Hebdo', // Button title
                    reportTitle: 'ALTICE_BFMTV', // Report Title
                    component: import('views/admin-views/MailReport'),
                    parent: 'altice_news_bfmtv',
                    headerTitle: 'admin.nav.users.report',
                    get path() {
                      return generatePath(this)
                    }
                  },
                  {
                    key: 'altice_news_bfmtv_monthly',
                    template: 'ALTICE_NEWS_BFMTV_MONTHLY',
                    date: 'lastMonth',
                    pages: [24, 80, 79, 67, 74, 71],
                    filter: true,
                    title: 'BFMTV - Monthly', // Button title
                    reportTitle: 'ALTICE_BFMTV', // Report Title
                    component: import('views/admin-views/MailReport'),
                    parent: 'altice_news_bfmtv',
                    headerTitle: 'admin.nav.users.report',
                    get path() {
                      return generatePath(this)
                    }
                  }
                ]
              },
              // REGIONS
              {
                key: 'altice_news_regions',
                path: `${ADMIN_PREFIX_PATH}/MailReport`,
                disableQuickOpen: true,
                title: 'Regions',
                component: import('views/admin-views/MailReport'),
                parent: 'altice_news',
                headerTitle: 'admin.nav.users.report',
                submenu: [
                  {
                    key: 'altice_news_regions_hebdo',
                    template: 'ALTICE_NEWS_REGIONS_HEBDO',
                    date: 'lastWeek',
                    pages: [104, 97, 105, 101, 103, 99, 98, 102, 96, 100],
                    filter: true,
                    title: 'BFM - Hebdo', // Button title
                    reportTitle: 'ALTICE_BFM_REGIONS', // Report Title
                    component: import('views/admin-views/MailReport'),
                    parent: 'altice_news_regions',
                    headerTitle: 'admin.nav.users.report',
                    get path() {
                      return generatePath(this)
                    }
                  },
                  {
                    key: 'altice_news_regions_montlhy',
                    template: 'ALTICE_NEWS_REGIONS_MONTHLY',
                    date: 'lastMonth',
                    pages: [104, 97, 105, 101, 103, 99, 98, 102, 96, 100],
                    filter: true,
                    title: 'BFM - Montlhy', // Button title
                    reportTitle: 'ALTICE_BFM_REGIONS', // Report Title
                    component: import('views/admin-views/MailReport'),
                    parent: 'altice_news_regions',
                    headerTitle: 'admin.nav.users.report',
                    get path() {
                      return generatePath(this)
                    }
                  },
                  {
                    key: 'altice_news_audience_bfm_region',
                    template: 'ALTICE_NEWS_AUDIENCE_REGION_BFMTV_HEBDO',
                    date: 'lastWeek',
                    pages: [104, 97, 105, 101, 103, 99, 98, 102, 96, 100],
                    filter: true,
                    title: 'BFM - Audience - Hebdo', // Button title
                    reportTitle: 'ALTICE_BFMTV', // Report Title
                    component: import('views/admin-views/MailReport'),
                    parent: 'altice_news_bfmtv',
                    headerTitle: 'admin.nav.users.report',
                    get path() {
                      return generatePath(this)
                    }
                  }
                ]
              },
              // RMC
              {
                key: 'altice_news_rmc',
                path: `${ADMIN_PREFIX_PATH}/MailReport`,
                disableQuickOpen: true,
                title: 'RMC',
                component: import('views/admin-views/MailReport'),
                parent: 'altice_news',
                headerTitle: 'admin.nav.users.report',
                submenu: [
                  {
                    key: 'altice_news_rmc_hebdo',
                    template: 'ALTICE_NEWS_RMC_HEBDO',
                    date: 'lastWeek',
                    pages: [110, 107, 29, 288, 26, 111, 118],
                    filter: true,
                    title: 'RMC - Hebdo', // Button title
                    reportTitle: 'ALTICE_RMC', // Report Title
                    component: import('views/admin-views/MailReport'),
                    parent: 'altice_news_rmc',
                    headerTitle: 'admin.nav.users.report',
                    get path() {
                      return generatePath(this)
                    }
                  },
                  {
                    key: 'altice_news_rmc_monthly',
                    template: 'ALTICE_NEWS_RMC_MONTHLY',
                    date: 'lastMonth',
                    pages: [110, 107, 29, 288, 26, 111, 118],
                    filter: true,
                    title: 'RMC - Monthly', // Button title
                    reportTitle: 'ALTICE_RMC', // Report Title
                    component: import('views/admin-views/MailReport'),
                    parent: 'altice_news_rmc',
                    headerTitle: 'admin.nav.users.report',
                    get path() {
                      return generatePath(this)
                    }
                  }
                ]
              },
              // RMC SPORT
              {
                key: 'altice_news_rmc_sport',
                path: `${ADMIN_PREFIX_PATH}/MailReport`,
                disableQuickOpen: true,
                title: 'RMC Sport',
                component: import('views/admin-views/MailReport'),
                parent: 'altice_news',
                headerTitle: 'admin.nav.users.report',
                submenu: [
                  {
                    key: 'altice_news_rmc_sport_hebdo',
                    template: 'ALTICE_NEWS_RMC_SPORT_HEBDO',
                    date: 'lastWeek',
                    pages: [114, 115],
                    filter: true,
                    title: 'RMC Sport - Hebdo', // Button title
                    reportTitle: 'ALTICE_RMC_SPORT', // Report Title
                    component: import('views/admin-views/MailReport'),
                    parent: 'altice_news_rmc_sport',
                    headerTitle: 'admin.nav.users.report',
                    get path() {
                      return generatePath(this)
                    }
                  },
                  {
                    key: 'altice_news_rmc_sport_montlhy',
                    template: 'ALTICE_NEWS_RMC_SPORT_MONTHLY',
                    date: 'lastMonth',
                    pages: [114, 115],
                    filter: true,
                    title: 'RMC Sport - Monthly', // Button title
                    reportTitle: 'ALTICE_RMC_SPORT', // Report Title
                    component: import('views/admin-views/MailReport'),
                    parent: 'altice_news_rmc_sport',
                    headerTitle: 'admin.nav.users.report',
                    get path() {
                      return generatePath(this)
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      // REWORLD
      {
        key: 'reworld',
        path: `${ADMIN_PREFIX_PATH}/MailReport`,
        disableQuickOpen: true,
        title: 'Reworld',
        component: import('views/admin-views/MailReport'),
        parent: 'mailReport',
        headerTitle: 'admin.nav.users.report',
        submenu: [
          {
            key: 'reworld_melty',
            path: `${ADMIN_PREFIX_PATH}/MailReport`,
            disableQuickOpen: true,
            title: 'Melty',
            component: import('views/admin-views/MailReport'),
            parent: 'reworld',
            headerTitle: 'admin.nav.users.report',
            submenu: [
              {
                key: 'reworld_melty_hebdo',
                template: 'REWORLD',
                date: 'lastWeek',
                pages: [270, 283, 289, 290, 291, 304, 315, 316, 317, 318, 320, 321, 322, 323, 324, 325, 416],
                filter: true,
                title: 'Melty - Hebdo', // Button title
                reportTitle: 'REWORLD_MELTY', // Report Title
                component: import('views/admin-views/MailReport'),
                parent: 'reworld',
                headerTitle: 'admin.nav.users.report',
                get path() {
                  return generatePath(this)
                }
              },
              {
                key: 'reworld_melty_monthly',
                template: 'REWORLD',
                date: 'lastMonth',
                pages: [270, 283, 289, 290, 291, 304, 315, 316, 317, 318, 320, 321, 322, 323, 324, 325, 416],
                filter: true,
                title: 'Melty - Monthly', // Button title
                reportTitle: 'REWORLD_MELTY', // Report Title
                component: import('views/admin-views/MailReport'),
                parent: 'reworld',
                headerTitle: 'admin.nav.users.report',
                get path() {
                  return generatePath(this)
                }
              }
            ]
          },
          {
            key: 'reworld_aufeminin',
            path: `${ADMIN_PREFIX_PATH}/MailReport`,
            disableQuickOpen: true,
            title: 'AuFeminin',
            component: import('views/admin-views/MailReport'),
            parent: 'reworld',
            headerTitle: 'admin.nav.users.report',
            submenu: [
              {
                key: 'reworld_aufeminin_hebdo',
                template: 'REWORLD',
                date: 'lastWeek',
                pages: [330, 130, 132, 131],
                filter: true,
                title: 'AuFeminin - Hebdo', // Button title
                reportTitle: 'REWORLD_AUFEMININ', // Report Title
                component: import('views/admin-views/MailReport'),
                parent: 'reworld',
                headerTitle: 'admin.nav.users.report',
                get path() {
                  return generatePath(this)
                }
              },
              {
                key: 'reworld_aufeminin_monthly',
                template: 'REWORLD',
                date: 'lastMonth',
                pages: [330, 130, 132, 131],
                filter: true,
                title: 'AuFeminin - Monthly', // Button title
                reportTitle: 'REWORLD_AUFEMININ', // Report Title
                component: import('views/admin-views/MailReport'),
                parent: 'reworld',
                headerTitle: 'admin.nav.users.report',
                get path() {
                  return generatePath(this)
                }
              }
            ]
          },
          {
            key: 'reworld_grazia',
            path: `${ADMIN_PREFIX_PATH}/MailReport`,
            disableQuickOpen: true,
            title: 'Grazia',
            component: import('views/admin-views/MailReport'),
            parent: 'reworld',
            headerTitle: 'admin.nav.users.report',
            submenu: [
              {
                key: 'reworld_grazia_hebdo',
                template: 'REWORLD',
                date: 'lastWeek',
                pages: [247],
                filter: true,
                title: 'Grazia - Hebdo', // Button title
                reportTitle: 'REWORLD_GRAZIA', // Report Title
                component: import('views/admin-views/MailReport'),
                parent: 'reworld',
                headerTitle: 'admin.nav.users.report',
                get path() {
                  return generatePath(this)
                }
              },
              {
                key: 'reworld_grazia_monthly',
                template: 'REWORLD',
                date: 'lastMonth',
                pages: [247],
                filter: true,
                title: 'Grazia - Monthly', // Button title
                reportTitle: 'REWORLD_GRAZIA', // Report Title
                component: import('views/admin-views/MailReport'),
                parent: 'reworld',
                headerTitle: 'admin.nav.users.report',
                get path() {
                  return generatePath(this)
                }
              }
            ]
          },
          {
            key: 'reworld_doctissimo',
            path: `${ADMIN_PREFIX_PATH}/MailReport`,
            disableQuickOpen: true,
            title: 'Doctissimo',
            component: import('views/admin-views/MailReport'),
            parent: 'reworld',
            headerTitle: 'admin.nav.users.report',
            submenu: [
              {
                key: 'reworld_doctissimo_hebdo',
                template: 'REWORLD',
                date: 'lastWeek',
                pages: [292, 134],
                filter: true,
                title: 'Doctissimo - Hebdo', // Button title
                reportTitle: 'REWORLD_DOCTISSIMO', // Report Title
                component: import('views/admin-views/MailReport'),
                parent: 'reworld',
                headerTitle: 'admin.nav.users.report',
                get path() {
                  return generatePath(this)
                }
              },
              {
                key: 'reworld_doctissimo_monthly',
                template: 'REWORLD',
                date: 'lastMonth',
                pages: [292, 134],
                filter: true,
                title: 'Doctissimo - Monthly', // Button title
                reportTitle: 'REWORLD_DOCTISSIMO', // Report Title
                component: import('views/admin-views/MailReport'),
                parent: 'reworld',
                headerTitle: 'admin.nav.users.report',
                get path() {
                  return generatePath(this)
                }
              }
            ]
          },
          {
            key: 'reworld_marmiton',
            path: `${ADMIN_PREFIX_PATH}/MailReport`,
            disableQuickOpen: true,
            title: 'Marmiton',
            component: import('views/admin-views/MailReport'),
            parent: 'reworld',
            headerTitle: 'admin.nav.users.report',
            submenu: [
              {
                key: 'reworld_marmiton_hebdo',
                template: 'REWORLD',
                date: 'lastWeek',
                pages: [135],
                filter: true,
                title: 'Marmiton - Hebdo', // Button title
                reportTitle: 'REWORLD_MARMITON', // Report Title
                component: import('views/admin-views/MailReport'),
                parent: 'reworld',
                headerTitle: 'admin.nav.users.report',
                get path() {
                  return generatePath(this)
                }
              },
              {
                key: 'reworld_marmiton_monthly',
                template: 'REWORLD',
                date: 'lastMonth',
                pages: [135],
                filter: true,
                title: 'Marmiton - Monthly', // Button title
                reportTitle: 'REWORLD_MARMITON', // Report Title
                component: import('views/admin-views/MailReport'),
                parent: 'reworld',
                headerTitle: 'admin.nav.users.report',
                get path() {
                  return generatePath(this)
                }
              }
            ]
          },
          {
            key: 'reworld_minutebuzz',
            path: `${ADMIN_PREFIX_PATH}/MailReport`,
            disableQuickOpen: true,
            title: 'Minutebuzz',
            component: import('views/admin-views/MailReport'),
            parent: 'reworld',
            headerTitle: 'admin.nav.users.report',
            submenu: [
              {
                key: 'reworld_minutebuzz_hebdo',
                template: 'REWORLD',
                date: 'lastWeek',
                pages: [253],
                filter: true,
                title: 'Minutebuzz - Hebdo', // Button title
                reportTitle: 'REWORLD_MINUTEBUZZ', // Report Title
                component: import('views/admin-views/MailReport'),
                parent: 'reworld',
                headerTitle: 'admin.nav.users.report',
                get path() {
                  return generatePath(this)
                }
              },
              {
                key: 'reworld_minutebuzz_monthly',
                template: 'REWORLD',
                date: 'lastMonth',
                pages: [253],
                filter: true,
                title: 'Minutebuzz - Monthly', // Button title
                reportTitle: 'REWORLD_MINUTEBUZZ', // Report Title
                component: import('views/admin-views/MailReport'),
                parent: 'reworld',
                headerTitle: 'admin.nav.users.report',
                get path() {
                  return generatePath(this)
                }
              }
            ]
          }
        ]
      },
      // Le Parisien
      {
        key: 'leparisien',
        path: `${ADMIN_PREFIX_PATH}/MailReport`,
        disableQuickOpen: true,
        title: 'Le Parisien',
        component: import('views/admin-views/MailReport'),
        parent: 'mailReport',
        headerTitle: 'admin.nav.users.report',
        submenu: [
          {
            key: 'leparisien_daily',
            template: 'LEPARISIEN',
            date: 'yesterday',
            pages: [17, 18],
            filter: true,
            title: 'Le Parisien - Quotidien', // Button title
            reportTitle: 'LEPARISIEN', // Report Title
            component: import('views/admin-views/MailReport'),
            parent: 'leparisien',
            headerTitle: 'admin.nav.users.report',
            get path() {
              return generatePath(this)
            }
            // sys_client: 2
          },
          {
            key: 'leparisien_hebdo',
            template: 'LEPARISIEN',
            date: 'lastWeek',
            pages: [17, 18],
            filter: true,
            title: 'Le Parisien - Hebdo', // Button title
            reportTitle: 'LEPARISIEN', // Report Title
            component: import('views/admin-views/MailReport'),
            parent: 'leparisien',
            headerTitle: 'admin.nav.users.report',
            get path() {
              return generatePath(this)
            }
            // sys_client: 2
          },
          {
            key: 'leparisien_monthly',
            template: 'LEPARISIEN',
            date: 'lastMonth',
            pages: [17, 18],
            filter: true,
            title: 'Le Parisien - Monthly', // Button title
            reportTitle: 'LEPARISIEN', // Report Title
            component: import('views/admin-views/MailReport'),
            parent: 'leparisien',
            headerTitle: 'admin.nav.users.report',
            get path() {
              return generatePath(this)
            }
            // sys_client: 2
          }
        ]
      },
      // EBRA
      {
        key: 'ebra',
        path: `${ADMIN_PREFIX_PATH}/MailReport`,
        disableQuickOpen: true,
        title: 'Ebra',
        component: import('views/admin-views/MailReport'),
        parent: 'mailReport',
        headerTitle: 'admin.nav.users.report',
        submenu: [
          {
            key: 'ebra_hebdo',
            template: 'EBRA',
            date: 'lastWeek',
            pages: [419],
            filter: true,
            title: 'Ebra - Hebdo', // Button title
            reportTitle: 'EBRA', // Report Title
            component: import('views/admin-views/MailReport'),
            parent: 'ebra',
            headerTitle: 'admin.nav.users.report',
            get path() {
              return generatePath(this)
            }
            // sys_client: 2
          },
          {
            key: 'ebra_monthly',
            template: 'EBRA',
            date: 'lastMonth',
            pages: [419],
            filter: true,
            title: 'Ebra - Monthly', // Button title
            reportTitle: 'EBRA', // Report Title
            component: import('views/admin-views/MailReport'),
            parent: 'ebra',
            headerTitle: 'admin.nav.users.report',
            get path() {
              return generatePath(this)
            }
            // sys_client: 2
          }
        ]
      },
      // CARREFOUR
      {
        key: 'carrefour',
        path: `${ADMIN_PREFIX_PATH}/MailReport`,
        disableQuickOpen: true,
        title: 'Carrefour',
        component: import('views/admin-views/MailReport'),
        parent: 'mailReport',
        headerTitle: 'admin.nav.users.report',
        submenu: [
          {
            key: 'carrefour_hebdo',
            template: 'CARREFOUR',
            date: 'lastWeek',
            pages: [327],
            filter: true,
            title: 'Carrefour - Hebdo', // Button title
            reportTitle: 'CARREFOUR', // Report Title
            component: import('views/admin-views/MailReport'),
            parent: 'carrefour',
            headerTitle: 'admin.nav.users.report',
            get path() {
              return generatePath(this)
            }
            // sys_client: 2
          },
          {
            key: 'carrefour_monthly',
            template: 'CARREFOUR',
            date: 'lastMonth',
            pages: [327],
            filter: true,
            title: 'Carrefour - Monthly', // Button title
            reportTitle: 'CARREFOUR', // Report Title
            component: import('views/admin-views/MailReport'),
            parent: 'carrefour',
            headerTitle: 'admin.nav.users.report',
            get path() {
              return generatePath(this)
            }
            // sys_client: 2
          }
        ]
      },
      // Loopsider
      {
        key: 'loopsider',
        path: `${ADMIN_PREFIX_PATH}/MailReport`,
        disableQuickOpen: true,
        title: 'Loopsider',
        component: import('views/admin-views/MailReport'),
        parent: 'mailReport',
        headerTitle: 'admin.nav.users.report',
        submenu: [
          {
            key: 'loopsider_daily',
            template: 'LOOPSIDER',
            date: 'yesterday',
            pages: [1],
            filter: true,
            title: 'Loopsider - Quotidien', // Button title
            reportTitle: 'LOOPSIDER', // Report Title
            component: import('views/admin-views/MailReport'),
            parent: 'loopsider',
            headerTitle: 'admin.nav.users.report',
            get path() {
              return generatePath(this)
            }
            // sys_client: 2
          },
          {
            key: 'loopsider_hebdo',
            template: 'LOOPSIDER',
            date: 'lastWeek',
            pages: [1],
            filter: true,
            title: 'Loopsider - Hebdo', // Button title
            reportTitle: 'LOOPSIDER', // Report Title
            component: import('views/admin-views/MailReport'),
            parent: 'loopsider',
            headerTitle: 'admin.nav.users.report',
            get path() {
              return generatePath(this)
            }
            // sys_client: 2
          },
          {
            key: 'loopsider_monthly',
            template: 'LOOPSIDER',
            date: 'lastMonth',
            pages: [1],
            filter: true,
            title: 'Loopsider - Monthly', // Button title
            reportTitle: 'LOOPSIDER', // Report Title
            component: import('views/admin-views/MailReport'),
            parent: 'loopsider',
            headerTitle: 'admin.nav.users.report',
            get path() {
              return generatePath(this)
            }
            // sys_client: 2
          }
        ]
      }
    ]
  },
  {
    rights: ['sysadmin'],
    key: 'notifications',
    title: 'admin.nav.notifications',
    icon: ApartmentOutlined,
    submenu: [
      {
        key: 'notifications',
        path: `${ADMIN_PREFIX_PATH}/notifications`,
        title: 'admin.nav.notifications',
        component: import('views/admin-views/Notifications'),
        headerTitle: 'admin.nav.notifications',
        parent: 'notifications',
        rights: ['sysadmin']
      }
    ]
  }
]

export default navigation
